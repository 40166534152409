import React from 'react'
import DefaultLink from '@/components/DefaultLink'

export interface ButtonLinkProps {
  to: string
  label: string
  className?: string
  secondary?: boolean
  blank?: boolean
}

const ButtonLink = ({ to, label, className, secondary, blank }: ButtonLinkProps) => {
  const theme = secondary
    ? 'border-secondary focus:border-primary  bg-secondary'
    : 'border-primary text-secondary focus:border-secondary  bg-primary'

  return (
    <DefaultLink
      className={`inline-block border-4 px-4 pb-1 pt-2 2xl:px-9 2xl:pb-[0.675rem] 2xl:pt-[1.125rem] 2xl:leading-[2.3rem] text-2xl md:text-4xl lg:text-5xl rounded-full 2xl:rounded-3xl ${theme} ${className}`}
      to={to}
      isBlank={blank}
    >
      {label}
    </DefaultLink>
  )
}
export default ButtonLink
