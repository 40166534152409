import React from 'react'
import { Link } from 'gatsby'

interface Data {
  children: React.ReactNode
  to: string
  isBlank?: boolean
  className?: string
  activeClassName?: string
  partiallyActive?: boolean
  icon?: string
}

const DefaultLink = ({ children, to, isBlank, className = '', activeClassName, partiallyActive, icon }: Data) => {
  const internal = /^\/(?!\/)/.test(to) && !isBlank

  if (internal) {
    return (
      <Link
        to={to}
        className={`${className} transition-all duration-200 relative group`}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
      >
        {icon && (
          <img
            className="absolute opacity-0 group-hover:opacity-100 duration-300 ease-in-out -left-8 top-2.5 2xl:left-1/2 2xl:-translate-x-1/2 2xl:-top-4"
            width="20"
            height="20"
            src={icon}
            alt=""
          />
        )}
        {children}
      </Link>
    )
  }
  return (
    <a href={to} target={`${isBlank === true ? '_blank' : '_self'}`} className={`${className}`}>
      {children}
    </a>
  )
}
export default DefaultLink
