import React from 'react'
import Nav from '@/components/Layout/Nav'
import { graphql, useStaticQuery } from 'gatsby'

export interface HeaderData {
  nav?: any
  lang?: any
}

const Header = ({ nav }: HeaderData) => {
  const { wp } = useStaticQuery(siteLogo)
  return <PureHeader nav={nav} logo={wp} />
}

export const PureHeader = ({ nav, logo }: { nav: any; logo: any }) => {
  return (
    <header className="py-4 2xl:py-1 bg-white">
      <Nav logo={logo?.crbThemeOptions?.gwSiteLogo} nav={nav} />
    </header>
  )
}

const siteLogo = graphql`
  query SiteLogo {
    wp {
      crbThemeOptions {
        gwSiteLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 144)
            }
          }
        }
      }
    }
  }
`

Header.defaultProps = {
  title: 'Demo',
  location: '/',
  lang: 'fi',
}

export default Header
