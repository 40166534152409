import React from 'react'

export interface ContainerProps {
  children: React.ReactNode
  className?: string
  classNameInner?: string
}

const Container = ({ children, className = '', classNameInner = '' }: ContainerProps) => {
  return (
    <div className={`w-full ${className}`}>
      <div className={`container mx-auto px-5 sm:px-10 2xl:px-2 ${classNameInner}`}>{children}</div>
    </div>
  )
}

export default Container
