import React, { useState } from 'react'
import DefaultLink from '@/components/DefaultLink'
import Container from '@/components/Layout/Container'
import { Squash as Hamburger } from 'hamburger-react'
import WheelSymbol from '@/components/Layout/Nav/assets/symbol-wheel.png'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

interface Navigation {
  id: string
  label: string
  parentId?: string
  url: string
}

interface NavData {
  logo?: any
  nav: {
    menuItems: {
      nodes: Array<Navigation>
    }
  }
}

const Nav = ({ nav: { menuItems }, logo }: NavData) => {
  const [mobileMenu, setMobileMenu] = useState(false)

  const navLogo = logo ? getImage(logo?.localFile) : null

  const menuWrapperClasses = `${
    !mobileMenu ? '-top-[110vh] 2xl-top-0' : 'top-0'
  } mt-24 2xl:mt-0 2xl:max-h-40 duration-500 ease-linear overflow-hidden 2xl:overflow-visible bg-white left-0 2xl:static text-primary`

  const menuContainerClasses = `${
    !mobileMenu ? 'max-h-0 2xl:max-h-40 2xl:w-full' : 'pt-8 2xl:pt-0 max-h-screen'
  } h-screen duration-500 2xl:overflow-visible bg-white items-center lowercase flex flex-col absolute 2xl:static w-full left-0 2xl:flex-row 2xl:flex-wrap 2xl:gap-4`

  const menuItemClasses = `${
    !mobileMenu ? 'opacity-0 2xl:opacity-100' : 'opacity-100'
  } duration-1000 text-primary text-4xl 2xl:text-5xl first capitalize-first hover:text-hovbrown focus:text-hovbrown leading-tight`

  return (
    <div
      className={`${
        mobileMenu ? 'max-h-screen' : 'max-h-24 2xl:max-h-40'
      } duration-500 h-screen overflow-hidden 2xl:overflow-visible mb-6`}
    >
      <div className={`h-24 2xl:h-40 transition-all duration-1000 w-full flex uppercase relative z-50`}>
        <nav id="nav" className="w-full relative">
          <Container className="mx-auto flex bg-white" classNameInner="flex justify-between">
            <DefaultLink className="z-50 w-24 2xl:w-36 mt-auto mb-auto flex-shrink-0" to="/">
              {navLogo && (
                <GatsbyImage
                  className="w-full h-auto mt-auto mb-auto logo transform hover:rotate-[30deg] duration-300 ease-in-out"
                  alt={''}
                  image={navLogo}
                />
              )}
            </DefaultLink>
            <div className={menuWrapperClasses}>
              <div className={menuContainerClasses}>
                {menuItems?.nodes.map((item: any) => {
                  if (item?.parentId === null) {
                    if (item?.childItems?.nodes.length > 0) {
                      return (
                        <Submenu
                          key={item.url}
                          item={item}
                          menuItemClasses={menuItemClasses}
                          childItems={item?.childItems}
                        />
                      )
                    } else {
                      return (
                        <DefaultLink
                          icon={WheelSymbol}
                          key={item.id}
                          className={menuItemClasses}
                          activeClassName="nav-active-item" // This is defined in globals.css
                          to={item.url}
                        >
                          {item.label}
                        </DefaultLink>
                      )
                    }
                  }
                })}
              </div>
            </div>
            <div className="items-right content-center ml-auto flex-wrap flex 2xl:hidden text-primary">
              <Hamburger toggled={mobileMenu} toggle={() => setMobileMenu(prev => !prev)} />
            </div>
          </Container>
        </nav>
      </div>
    </div>
  )
}
export default Nav

const Submenu = ({ item, menuItemClasses, childItems }: any) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <div className="relative flex flex-col items-center justify-center 2xl:block group">
        <button
          onClick={() => setVisible(prev => !prev)}
          key={item.id}
          className={`${menuItemClasses} ${visible ? 'underline' : ''} flex items-center relative`}
        >
          {WheelSymbol && (
            <img
              className="absolute opacity-0 group-hover:opacity-100 duration-300 ease-in-out -left-8 top-2.5 2xl:left-1/2 2xl:-translate-x-1/2 2xl:-top-4"
              width="20"
              height="20"
              src={WheelSymbol}
              alt=""
            />
          )}
          {item.label}{' '}
        </button>
        {visible && (
          <ul className="2xl:absolute items-center 2xl:items-start lowercase p-8 2xl:p-4 2xl:rounded-md bg-primary text-white 2xl:shadow-md 2xl:text-primary 2xl:bg-white flex flex-col 2xl:w-96 z-50">
            {childItems?.nodes?.map((item: any) => (
              <DefaultLink key={item.url} to={item.url} className="text-3xl flex text-center 2xl:text-left">
                <span className="pr-2 hidden 2xl:block">{'>'} </span>
                <p className="capitalize-first">{item.label}</p>
              </DefaultLink>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}
