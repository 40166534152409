import React from 'react'
import Container from '@/components/Layout/Container'
import SocialShare from '@/components/SocialShare'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Overlay from './assets/overlay-wheel.webp'
import DefaultLink from '@/components/DefaultLink'

const Footer = () => {
  const {
    wp: { themeOptions },
    privacyPolicy,
  } = useStaticQuery(footerQuery)

  const { content, footerLogo } = themeOptions

  return <PureFooter logo={footerLogo} content={content} privacyPolicy={privacyPolicy} />
}

export const PureFooter = ({ logo, content, privacyPolicy, hasSocialShare = true }: any) => {
  const image = logo && getImage(logo?.localFile)
  return (
    <>
      {hasSocialShare && <SocialShare />}
      <footer className="bg-primary text-white pt-5 2xl:pt-8 footer min-h-[400px] overflow-hidden relative">
        <Container classNameInner="flex flex-row gap-4 md:gap-24">
          {image && (
            <div className="py-8 lg:py-0 bg-white px-4 w-44 lg:w-52 h-auto max-h-40 lg:h-64 lg:max-h-96 grid place-content-center">
              <GatsbyImage objectFit="contain" className=" bg-white" image={image} alt={'Logo'} />
            </div>
          )}
          <div className="pt-6 md:pt-12 pb-12 max-w-2xl w-full min-h-[400px] flex flex-col md:justify-between">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div>
              {privacyPolicy?.title && (
                <DefaultLink
                  className="text-xs md:text-xl border-b pb-1 text-white hover:text-secondary hover:border-secondary focus:text-secondary focus:border-secondary"
                  to={privacyPolicy?.uri}
                >
                  {/* {privacyName} */}
                  {privacyPolicy.title}
                </DefaultLink>
              )}
            </div>
          </div>

          <img alt="" width="100" height="100" className="absolute -right-20 md:-right-40 lg:-right-20 -bottom-32 md:-bottom-20 w-80 md:w-96" src={Overlay} />
        </Container>
      </footer>
    </>
  )
}

const footerQuery = graphql`
  query FooterQuery {
    wp {
      themeOptions: crbThemeOptions {
        content: gwFooterContent
        footerLogo: gwFooterLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 200)
            }
          }
        }
      }
    }
    privacyPolicy: wpPage(isPrivacyPage: { eq: true }) {
      title
      uri
    }
  }
`

export default Footer
