import React from 'react'
import ShareIcon from './assets/icon-share.webp'

interface ProviderProps {
  name: string
  baseUrl: string
  ariaLabel: string
  content?: string
  currentUrl?: string
}

const socialProviders: ProviderProps[] = [
  {
    name: 'Facebook',
    baseUrl: 'https://facebook.com/sharer/sharer.php?u=',
    ariaLabel: 'Jaa Facebookissa',
  },
  {
    name: 'Twitter',
    baseUrl: 'https://twitter.com/intent/tweet/?',
    content: 'text=',
    currentUrl: '&url=',
    ariaLabel: 'Jaa Twitterissä',
  },
  {
    name: 'WhatsApp',
    baseUrl: 'whatsapp://send?',
    content: 'text=',
    ariaLabel: 'Jaa WhatsApissa',
  },
]
export interface SocialShareProps {
  pageTitle: string
  bg?: boolean
}
const SocialShare = ({ pageTitle, bg }: SocialShareProps) => {

  const location = (typeof window !== 'undefined') ? window.location.href : 'https://rengasvalmistajat.fi/';

  const currentUrl = encodeURIComponent(location)
  const title = encodeURIComponent(pageTitle)

  const listItemClasses = 'flex content-center items-center'

  const copyToClipboard = () => {
    typeof navigator !== 'undefined' ? navigator.clipboard.writeText(location) : ''
  }

  return (
    <section
      className={`my-8 justify-center flex gap-4 lg:gap-8 px-8 lg:px-0 mx-auto ${bg ? 'bg-white' : 'bg-transparent'}`}
    >
      <img className="w-16 object-contain" height="76" width="64" src={ShareIcon} alt="" />
      <ul className="flex flex-wrap gap-x-4 lg:gap-8 font-helvetica lg:justify-center">
        <li className={`w-full md:w-auto bg-slate-400 ${listItemClasses}`}>
          <span className="font-thin text-md lg:text-2xl">Jaa sivu:</span>
        </li>
        {socialProviders.map(provider => {
          let link = provider.baseUrl

          if (provider?.content) link += provider.content + title // Set content param in url
          if (provider?.currentUrl) link += provider.currentUrl // Set url param in url

          link += currentUrl

          return (
            <li key={provider.name} className={listItemClasses}>
              <a
                aria-label={provider.ariaLabel}
                target="_blank"
                rel="noopener noreferrer"
                href={link}
                className={`underline transition-all hover:underline font-thin text-md lg:text-2xl`}
              >
                {provider.name}
              </a>
            </li>
          )
        })}
        <li className={listItemClasses}>
          <button
            onClick={copyToClipboard}
            className={`underline transition-all hover:underline hover:cursor-pointer font-thin text-md lg:text-2xl`}
          >
            Kopioi linkki
          </button>
        </li>
      </ul>
    </section>
  )
}
SocialShare.defaultProps = {
  pageTitle: 'Rengasvalmistajat: ',
}
export default SocialShare
