import ButtonLink from '@/components/ButtonLink'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Overlay from './assets/overlay-wheel.webp'
import { Link } from 'gatsby'

export interface HeroProps {
  title?: string
  image?: any
  overlay?: boolean
  archive?: boolean
  button?: {
    link: string
    linkText: string
    blank?: string
  }
  disableBackbutton?: boolean
  backPath?: string
}

const Hero = ({
  title,
  image,
  button,
  overlay = false,
  archive = false,
  disableBackbutton = false,
  backPath,
}: HeroProps) => {
  // console.log('Hello from hero component', button)

  const background = image ? getImage(image?.node?.localFile) : false

  const withOverlayClasses =
    'flex flex-col items-center justify-center p-4 2xl:p-0 h-full w-full mr-18 2xl:top-1/2 2xl:-translate-y-1/2 2xl:w-1/2 2xl:right-0 absolute z-10'

  const withoutOverlayClasses = 'xl:p-4 2xl:p-0 h-full w-full z-10'

  const classes = overlay ? withOverlayClasses : withoutOverlayClasses

  return (
    <section
      className={`flex flex-col-reverse relative overflow-hidden ${
        overlay || !archive ? 'max-h-[550px] gap-8 lg:gap-0' : 'max-h-[350px]'
      } `}
    >
      <div className={classes}>
        {!overlay && !archive && !disableBackbutton && backPath && (
          <BackButton noHeroImg={!background} backPath={backPath} label={'Takaisin'} />
        )}
        {title && (
          <h1
            className={`${overlay || archive ? 'text-white' : 'text-primary px-8 lg:px-0'} font-medium ${
              overlay ? 'text-5xl md:text-8xl xl:text-9xl' : ' text-5xl md:text-7xl'
            } leading-[2.5rem] md:leading-[3.5rem] xl:leading-[5rem] tracking-normal ${
              archive
                ? 'text-center my-0 lg:my-0 w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
                : ''
            }`}
          >
            {title}
          </h1>
        )}
        {button && (
          <ButtonLink secondary className="mx-auto" to={button.link} label={button.linkText} blank={button.blank} />
        )}
      </div>
      {background && <GatsbyImage alt="" image={background} />}
      {overlay && (
        <img
          alt=""
          width="500"
          height="540"
          className="absolute left-0 bottom-0 w-32 md:w-96 lg:w-auto"
          src={Overlay}
        />
      )}
    </section>
  )
}
export default Hero

export interface BackButtonProps {
  noHeroImg?: boolean
  label: string
  backPath: string
}
export const BackButton = ({ noHeroImg = false, label = 'Takaisin', backPath }: BackButtonProps) => {
  return (
    <Link
      className={`${
        noHeroImg ? 'ml-8' : '-mt-6 ml-2'
      } lg:mt-4  lg:ml-0 uppercase text-xl lg:text-4xl font-light flex items-center`}
      to={backPath}
    >
      <span className="text-2xl lg:text-5xl mr-2">{'<'}</span> {label}
    </Link>
  )
}
